/*Modal Title*/
.css-xeqldi h4 {
    font-size: 18px;
    color: #474D66;
    font-weight: 400;
    display: inline-block;    
    width: 100%;
};

/*Close Button Modal*/
.css-xeqldi button.css-1tyjqqv {
    position: absolute;
    right: 40px
};

div.campoRevisao.Primeiro {
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.checkBoxFill {
    fill: #fff;
}