body {
  font: 400 14px Roboto, sans-serif;
  background: #E6E8F0;
  -webkit-font-smoothing: antialiased;
}

.form {
  width: 90%;
  background: #fff;
  box-shadow: 0 0 14px 0 rgb(0 0 0 / 2%);
  border-radius: 2px;
  padding: 30px 20px;  
}

.logon-container {
  width: 100%;
  max-width: 1120px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

div:not(.reset).logon-container section.form {  
  height: 493px;  
}

.logon-container section.form {
  width: 390px;  
  border-radius: 8px;  
}

.logon-container section.form form h1 {
  font-size: 24px;
  margin-bottom: 21px;
  margin-top: 27px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.logon-container section.form form img {
  margin-top: 40px;  
  width: 166px;
  height: 98px;
}

.center{
  text-align : center;  
 }

 a {
  color: #3366FF;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;  
 }

 .logon-container section.form form label {
  font-weight: 400;  
}

.password input {  
  padding-right: 40px !important;
  width: 390px;
}

.ub-pst_fixed {
  z-index: 20;
}

.css-1sugtjn, [data-css-1sugtjn]{
  z-index: 99999;
}

.resetText {
  margin-top: 20px;
}

.resetText spam {
  margin-bottom: 15px;
}

.securityFields {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.passwordEye { 
  margin-top: 34px; 
  margin-left: 360px;
  position: absolute;
  z-index: 3;  
  fill: #8F95B2 !important;
}

.containerPassword svg.passwordEye {
  margin-top: 26px;
}

.reset {
  margin-top: 35px !important;
}

.containerPassword {  
  display: flex;
  align-items: center;  
}

.shieldIcon {  
  margin-left: 355px;
  margin-bottom: 20px;
  position: absolute;
  z-index: 1;
  padding: 5px;
  padding-bottom: 2px;
  border-radius: 200px;
}

.line {  
  width: 90px;
  height: 2px;
  margin-right: 5px;
}

.mb-25 {
  margin-bottom: 25px;
}

.AlignRight {  
  justify-content: space-between;
}

.securityFields span {
  font-size: 10px;
}