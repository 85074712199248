/*Modal Title*/
.css-xeqldi h4 {
    font-size: 18px;
    color: #474D66;
    font-weight: 400;
    display: inline-block;    
    width: 100%;
}

/*Close Button Modal*/
.css-xeqldi button.css-1tyjqqv {
    position: absolute;
    right: 40px;
    z-index: 9999;
}

.PhoneInput {
    height: 32px !important;
    font-size: 12px !important;
    color: #696F8C !important;
    padding: 1px 0px 1px 60px !important;    
}