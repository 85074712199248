.SESSÃO_DOC_SÓCIO_REPRESENTANTE {
  margin-top: 30px;
}

.inputRedSelect input {
  color: #D14343;
  background: #fff;
  border-color: #D14343;
}

input.inputRedSelect{
  color: #D14343 !important;
  background: #fff;
  border-color: #D14343 !important;
}

input.inputGreenSelect{
  border-color: #429777 !important; 
  background: #F5FBF8 !important;
}

.inputRedSelect button {
  color: #D14343;
  background: #fff;
  border-color: #D14343;
}

.inputRedSelect select {
  color: #D14343;
  background: #fff;
  border-color: #D14343;
}

.historyDoc {
  margin: 20px 0 30px 0;
  align-items: center;
  display: flex;
}

.historyDoc span {
  margin-left: 5px;
}