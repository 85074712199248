@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

.form-acesso {
  width: 90%;
  background: #fff;
  box-shadow: 0 0 14px 0 rgb(0 0 0 / 2%);
  border-radius: 2px;
  padding: 20px;  
}

/*.btn-recibo{
  position: relative;
  top: -10px;
*/

.btn-recibo-2-mobile{
  display: none;
}

/*DNA Color Buttons*/
.btn-dna-green{
  /* background: #5EA99B !important;
  border: 1px solid #5EA99B !important;
  color: #fff !important; */
  color: #fff;
  display: flex;
  width: 268px;
  height: 56px;
  padding: 4px 22px;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  gap: 8px;
  border-radius: 10px;
  /* background: var(--primary-500, #01988C); */
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
  background: var(--primary-500, #01988C);
}

.btn-dna-green:hover{
  /* background: #22806E !important;
  border: 1px solid #22806E !important; */
  opacity: 0.8;
  background: var(--primary-500, #01988C);
}

.btn-dna-yellow{
  background: #11DC6E !important;
  border: 1px solid #11DC6E !important;
  color: #fff !important;
}

.btn-dna-yellow:hover{
  background: #11DC6E !important;
  border: 1px solid #11DC6E !important;
  opacity: 0.8;
} 

.btn-dna-orange{
  background: #10A454 !important;
  border: 1px solid #10A454 !important;
  color: #fff !important;
}

.btn-dna-orange:hover{
  background: #10A454 !important;
  border: 1px solid #10A454 !important;
  opacity: 0.8;
} 

/*******************/

.acesso-container {
  width: 100%;
  max-width: 1120px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

div:not(.reset).acesso-container section.form-acesso {  
  height: auto;  
}

.acesso-container section.form-acesso {
  width: 100%;
  max-width: 390px;  
  border-radius: 8px;  
}

.acesso-container section.form-acesso form h1 {
  font-size: 24px;
  margin-bottom: 21px;
  margin-top: 27px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #00AD9D;
}

.acesso-container section.form-acesso form h3 {
  margin-bottom: 21px;
  margin-top: 27px;
  text-align: center;
}

.acesso-container section.form-acesso form img {
  margin-top: 40px;  
  width: 166px;
  height: 98px;
}

.center{
  text-align : center;  
 }

 a {
  text-decoration: none;
 }

 .acesso-container section.form-acesso form label {
  font-weight: 400;  
  color: #696F8C;
}

.password input {  
  padding-right: 40px !important;
  width: 390px;
}

.ub-pst_fixed {
  z-index: 20;
}

.css-1sugtjn, [data-css-1sugtjn]{
  z-index: 99999;
}

.resetText {
  margin-top: 20px;
}

.resetText span {
  margin-bottom: 15px;
}

.securityFields {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.passwordEye { 
  margin-top: 34px; 
  margin-left: 360px;
  position: absolute;
  z-index: 3;  
  fill: #8F95B2 !important;
}

.containerPassword svg.passwordEye {
  margin-top: 26px;
}

.reset {
  margin-top: 35px !important;
}

.containerPassword {  
  display: flex;
  align-items: center;  
}

.shieldIcon {  
  margin-left: 355px;
  margin-bottom: 20px;
  position: absolute;
  z-index: 1;
  padding: 5px;
  padding-bottom: 2px;
  border-radius: 200px;
}

.line {  
  width: 90px;
  height: 2px;
  margin-right: 5px;
}

.mb-25 {
  margin-bottom: 25px;
}

.AlignRight {  
  justify-content: space-between;
}

.securityFields span {
  font-size: 10px;
}

/*Acompanhamento do Status*/
.row-acp{
  padding: 20px 20px;  
  background: #E6E8F0;
}

.row-acp .coll,
.row-acp .container-info .row,
.row-acp .container-info .row-2,
.row-acp .container-info .row-3{
  background: #fff;
  /* Elevation / 1 */
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 60px;
  color: #474D66;
}

.btn-update-user{
  display: block;
}

.row-acp .container-info .row-3{
  padding: 40px 60px;
  margin-bottom: 10px;
}

.row-acp .container-info .row-3 label{
  color: #474D66;
  font-weight: 400;
}

.row-acp .container-info .row-3 h2{
  margin-bottom: 20px;
}

.row-acp .container-info .row-3 input,
.row-acp .container-info .row-3 select{
  height: 48px !important;
  font-size: 13px !important;
}

.row-acp .container-info .row-3 .inputGreenSelect svg{
  margin-top: 0;
}

.row-acp .container-info .row-3 .rowForm div:first-child{
  margin-right: 0;
}

.row-acp .container-info .row-3 .rowForm{
  gap: 15px;
}

.row-acp .container-info .row-3 .rowForm p{
  margin-left: 5px;
}

.row-acp .container-info .title-badge{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #474D66;
}

.row-acp .container-info .row-2{
  margin: 0 auto;
  width: 100%;
  max-width: 720px;
  padding: 0;
  background: inherit;
  margin-top: 25px;
}

.row-acp .container-info .row-2 .mb20{
  margin-bottom: 10px;
}

.row-acp .coll .info-user{
  font-size: 16px;
  margin-bottom: 25px;
  font-weight: 600;
  color: #474D66;
}

.row-acp .coll .info-user span{
  font-weight: bold;
}

.row-acp .coll .content{
  font-size: 16px;
  margin-bottom: 15px;
}

.row-acp .coll .info{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  font-weight: 600;
}

.row-acp .coll .info p{
  font-weight: 600;
}

.row-acp .coll .info .imovel{
  font-weight: 400;
  font-size: 14px;
}

.row-acp .coll .info .imovel path{
  fill: #8F95B2;
}

.icon-btn-primary{
  margin-right: 8px;
}
.icon-btn-primary path{
  fill: #ffff;
}

.row-acp .coll .info .icon-imovel-map{
  width: 16px;
  height: 16px;
}

.position-check{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 10px;
  top: 10px;
}

/*.row-acp .coll .row-status{
  margin-top: 25px;
  border: 1px solid;
  border-radius: 5px;
  padding: 20px;
}

.row-acp .coll .row-status .title{
  margin-bottom: 15px;
}

.row-acp .coll .row-status .title,
.row-acp .coll .row-status .content{
  text-align: center;
}

.row-acp .coll .row-status .content{
  font-size: 16px;
}

.row-acp .coll .row-status .step-status ul{
  display: flex;
  justify-content: space-between;
}

.row-acp .coll .row-status .step-status ul li{
  list-style: none;
}*/

.row-acp .container-info .row-historico{
  width: 100%;
  max-width: 720px;
  padding: 0;
}

.row-acp .container-info .row-historico .coll-historico h2{
  padding: 40px 0 0 40px;
  /* margin-bottom: 15px !important; */
}

.row-acp .container-info .row-historico .coll-historico ul li{
  padding: 20px 40px;
}

.row-acp .container-info .row{
  margin-top: 10px
}

.row-acp .container-info .row .coll-status .header{
  text-align: center;
}

.row-acp .container-info .row .coll-status .header .title{
  /* color: #00AD9D; */
  color: #01988C;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
}

.row-acp .container-info .row .coll-status .header .content{
  /* color: #474D66; */
  color: #74848B;
  font-weight: 400;
  font-size: 14px;
}

.row-acp .container-info .row .coll-status .step-status ul{
  display: flex;
  justify-content: space-between;
  margin: 60px auto 40px
}

.row-acp .container-info .row .coll-status .step-status ul li{
  list-style: none;
  text-align: center;
  /*display: flex;
  width: 100%;
  max-width: 158px;*/
}

.row-acp .container-info .row .coll-status .step-status ul li .step{
  width: 32px;
  height: 32px;
  border-radius: 30px;
  display: block;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.row-acp .container-info .row .coll-status .step-status ul li .check{
  background: #DCF2EA;
}
.row-acp .container-info .row .coll-status .step-status ul li .loading{
  background: #D6E0FF;
}
.row-acp .container-info .row .coll-status .step-status ul li .waiting{
  background: #EDEFF5;
}

.row-acp .container-info .row .coll-status .step-status ul li span{
  text-align: center;
  color: #474D66;
  display: block;
  margin-top: 10px;
}
.row-acp .container-info .row .coll-status .step-status ul li span.loading{
  color: #3366FF;
  background: none;
}

.row-acp .container-info .row .coll-status .step-status ul li svg{
  display: block;
  margin: 0 auto;
}

.row-acp .container-info .row .coll-status .step-status ul li .icon-check{
  width: 20px;
  height: 20px;
}
.row-acp .container-info .row .coll-status .step-status ul li .icon-check path{
  fill: #52BD94;
}
.row-acp .container-info .row .coll-status .step-status ul li .icon-loading path{
  fill: #3366FF;
}

/* .row-acp .container-info .row .coll-status .header .btn-feedback{
  background: #FFFFFF;
  border: 1px solid #D8DAE5;
  border-radius: 4px;

  color: #696F8C;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  max-width: 200px;
  width: 100%;
  margin-top: 25px;
} */

.row-acp .container-info .row .coll-status .header .btn-feedback,
.avaliacao-feedback-dialog .btn-feedback{
  color: #fff;
  display: flex;
  width: 268px;
  height: 56px;
  padding: 4px 22px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  /*background: var(--primary-500, #01988C);*/
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
}

.row-acp .container-info .row .coll-status .header .btn-feedback:hover,
.avaliacao-feedback-dialog .btn-feedback:hover{
  opacity: 0.8;
  background: var(--primary-500, #01988C);
}

.btn-feedback-enable{
  background: var(--primary-500, #01988C);
}

.btn-feedback-disable{
  background: var(--neutral-300, #A7B7BE);
  cursor: not-allowed;
  pointer-events: none;
}

.row-acp .container-info .row .coll-status .header .btn-feedback .icon-feedback{
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.row-acp .container-info .row .coll-status .header .btn-feedback .icon-feedback path{
  fill: #8F95B2;
}

.row-acp .container-info .row .coll-historico h2{
  font-weight: 600;
  font-size: 16px;
  color: #474D66;
  margin-bottom: 25px;
  /* margin-bottom: 0; */
}

.row-acp .container-info .row .coll-historico ul li{
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  list-style: none;
  align-items: left;
  border-bottom: 2px solid #E0E7EB;
  padding-bottom: 25px;
}

.row-acp .container-info .row .coll-historico ul li:last-child{
  border-bottom: none;
}

.row-acp .container-info .row .coll-historico ul li .data{
  /*color: #8F95B2;*/
  color: #74848B;
  font-weight: 400;
  font-size: 12px;
}

.row-acp .container-info .row .coll-historico ul li .text{
  /*color: #474D66;*/
  color: #5D696F;
  font-weight: 500;
  font-size: 14px;
}

.row-acp .container-info .row .coll-historico ul li .badge{
  margin: 8px 0;
  background: #E7F4D7;
  color: #6B9539
}

.row-acp .container-info .row .coll-ajuda .title{
  margin-bottom: 20px;
}

.row-acp .container-info .row .coll-ajuda .title span{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #474D66;
}

.row-acp .container-info .row .coll-ajuda .content{
  display: flex;
  justify-content: space-between;
}

.row-acp .container-info .row .coll-ajuda .content .btn-contato{
  max-width: 302px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  font-family: Montserrat !important;
  margin-right: 15px;
  padding: 27px;
  border-radius: 10px;
}

.row-acp .container-info .row .coll-ajuda .content .btn-ajuda{
  font-family: Montserrat;
  max-width: 264px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #696F8C;
  font-family: Montserrat !important;
  padding: 27px;
  border-radius: 10px;
  
  /* Neutral / N0 */
  background: #FFFFFF;

  /* Neutral / N400 */
  border: 1px solid #D8DAE5;
  /*border-radius: 4px;*/
}

.row-acp .container-info .row .coll-ajuda .content .btn-contato .icon-whatsapp{
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.row-acp .container-info .row .coll-ajuda .content .btn-ajuda .icon-help{
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.row-acp .container-info .row .coll-ajuda .content .btn-ajuda .icon-help path{
  fill: #8F95B2;
}

.row-acp .nav-mobile{
  display: none;
}

.ajuda-temporaria-dialog {
  padding: 20px;
}

.ajuda-temporaria-dialog > div{
  padding-top: 20px;
  margin-bottom: 20px;
}

.ajuda-temporaria-dialog h2{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Neutral / N800 */
  color: #474D66;
}

.ajuda-temporaria-dialog li{
  list-style: none;
  margin-bottom: 30px
}

.ajuda-temporaria-dialog ul li .title{
  /* color: #474D66; */
  color: #464F53;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
}

.ajuda-temporaria-dialog ul li .content{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* color: #474D66; */
  color: #464F53;
}

.avaliacao-feedback-dialog h2{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  /* or 133% */

  /* Neutral / N800 */
  color: #474D66;
  margin-bottom: 15px;
}

.avaliacao-feedback-dialog p{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #474D66;
}

.avaliacao-feedback-dialog > div > button{
  /*top: 25px;*/
  top: -22px;
}

.avaliacao-feedback-dialog ul {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

.avaliacao-feedback-dialog ul li{
  list-style: none;
  cursor: pointer;
  border: 1px solid #D8DAE5;
  border-radius: 4px;
  padding: 14px 18px;
  margin-right: 15px;
}

.avaliacao-feedback-dialog ul li:hover,
.avaliacao-feedback-dialog ul li.enable{
  background: #00AD9D;
  border: 1px solid #00AD9D;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
}

.avaliacao-feedback-dialog ul li p{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* Neutral / N800 */
  color: #474D66;
}

.avaliacao-feedback-dialog .btn-feedback{
  width: 100%;
  padding: 23px;
  margin-bottom: 25px;
  font-size: 14px;
}

.avaliacao-feedback-dialog .btn-feedback svg{
  width: 10px;
  height: 10px;
  /* margin-left: 10px; */
  margin-left: 0;
}

.avaliacao-feedback-dialog .btn-feedback svg path{
  fill: #fff
}

.avaliacao-feedback-dialog .feedback-error{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #D14343;
  display: flex;
  position: relative;
  top: -10px;
}

.avaliacao-feedback-dialog .feedback-error svg{
  margin-right: 5px;
}

.avaliacao-feedback-dialog .feedback-error svg path{
  fill: #D14343;
  width: 12px;
  height: 12px;
}

/* .avaliacao-feedback-dialog .end-feedback{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
} */

.avaliacao-feedback-dialog .end-feedback img{
  margin: 50px auto 25px;
  display: block;
}

.avaliacao-feedback-dialog .end-feedback p{
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #00AD9D;
  margin-bottom: 120px;
}

.principal-temporario{

}

.principal-temporario .btn-default{
  background: #5EA99B;
  color: #fff;
  max-width: 312px;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 20px;
  margin-top: 20px;
}

/*.documentos-temporaria-dialog{
  position: relative;
}*/

.line-box-doc{
  display: none;
}

.documentos-temporaria-dialog h2{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #474D66;
}

.documentos-temporaria-dialog .content{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #696F8C;
  margin-top: 15px;
  margin-bottom: 35px;
}

.documentos-temporaria-dialog .content span{
  font-family: 'Montserrat';
  font-weight: 700;
}

.documentos-temporaria-dialog .row-documents .title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #5EA99B;
  margin-bottom: 10px;
}

.documentos-temporaria-dialog .row-documents .row-btn{
  gap: 5px;
  display: flex;
}

.documentos-temporaria-dialog .row-documents .badge{
  margin-bottom: 15px;
}

.documentos-temporaria-dialog .row-documents label{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #474D66;
}

.documentos-temporaria-dialog .label{
  margin-bottom: 8px;
  display: block;
}

.documentos-temporaria-dialog select,
.documentos-temporaria-dialog .evergreen-file-picker-root{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8F95B2;
}

.documentos-temporaria-dialog .line{
  height: 1px;
  background: #D8DAE5;
  width: 100%;
  margin: 30px 0;
}

.documentos-temporaria-dialog .btn-add-doc{
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #696F8C;
  padding: 20px;
}

.documentos-temporaria-dialog .btn-add-doc,
.documentos-temporaria-dialog .btn-add-doc:hover{
  border: none;
}

.documentos-temporaria-dialog .btn-add-doc:hover{
  background: #F4F5F9;
}

.documentos-temporaria-dialog .line-btn{
  margin: 20px 0 30px 0;
  display: flex;
}

.documentos-temporaria-dialog .btn-show-doc{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #696F8C;
  border-color: #D8DAE5;
  padding: 20px;
  width: 100%;
}

.documentos-temporaria-dialog .btn-dell-doc{
  background: #D14343;
  padding: 20px;
}

.documentos-temporaria-dialog .btn-dell-doc:hover{
  background: #D14343;
  opacity: 0.8;
}

.documentos-temporaria-dialog .btn-save{
  height: 56px;
  width: 100%;
  padding: 20px;
  margin: 30px 0;
  /*background: #5EA99B;*/
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  background: var(--primary-500, #01988C);
  /* grid-gap: 16px;
  gap: 16px; */
  border-radius: 10px;

  /*position: fixed;
  bottom: 0;*/
}

.documentos-temporaria-dialog .row-content{
  margin-bottom: 0;
}

.documentos-temporaria-dialog .btn-save:hover{
  /* background: #5EA99B;
  opacity: 0.8; */

  opacity: 0.8;
  background: var(--primary-500, #01988C);
}

.documentos-temporaria-dialog .errorMessage{
  position: relative;
  top: -12px;
}

.documentos-temporaria-dialog .errorMessage span{
  color: #D14343;
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 400;
  position: relative;
  top: -2px;
  margin-left: 5px;
}

.documentos-temporaria-dialog .row-desktop{
  display: block;
}

.documentos-temporaria-dialog .row-mobile{
  display: none;
}

.inputGreenSelect select,
.inputGreenSelect input,
.upload-recibo-doc-1 .inputGreenSelect input.evergreen-file-picker-text-input,
.inputGreenSelect button {
  border-color: #429777;
  background: #F5FBF8
}

.inputDefault,
.inputDefault select,
.inputDefault input,
.upload-recibo-doc-1 .inputDefault input.evergreen-file-picker-text-input,
.inputDefault button {
  border-color: #ADC2FF;
  background: #fff;
}

.inputErrorSelect,
.inputErrorSelect select,
.inputErrorSelect input,
.upload-recibo-doc-1 .inputErrorSelect input.evergreen-file-picker-text-input,
.inputErrorSelect button {
  border-color: #FF0000;
  background: '#fff';
}

.inputDisabled,
.inputDisabled select,
.inputDisabled input,
.upload-recibo-doc-1 .inputDisabled input.evergreen-file-picker-text-input,
.inputDisabled button{
  background: #F4F6FA;
  border-color: #E6E8F0;
  color: #8F95B2;
  cursor: no-drop;
  font-weight: 500;
}

.inputDisabled button{
  background: #fff;
  color: #C1C4D6;
  cursor: no-drop;
  font-weight: 500;
}

.disabled-show-doc{
  color: #C1C4D6;
  border-color: #E6E8F0 !important;
}

.coll-neutro{
  max-width: 720px;
  width: 100%;
  margin: 0 auto 10px;
  display: flex;
  justify-content: flex-end;
}

.coll-neutro .btn-sair{
  background: #FFFFFF;
  border: 1px solid #D8DAE5;
  border-radius: 4px;
  color: #696F8C;
} 

.coll-neutro .icon-sair{
  width: 10px;
  height: 12px;
  margin-right: 5px;
}

.coll-neutro .icon-sair path{
  fill: #8F95B2;
}

.header-temp{
  width: 100%;
  position: relative;
}

.header-temp nav{
  background: #fff;
  padding: 20px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 5px 8px -4px;
  margin-bottom: 15px;
  z-index: 10;
  position: relative;
}

.header-temp nav .btn-sair{
  background: #FFFFFF;
  border: 1px solid #D8DAE5;
  border-radius: 4px;
  color: #696F8C;
  padding: 20px;
  font-size: 16px;
} 

.nav-mobile .nav-faq{
  border-bottom: 1px solid #D8DAE5;
}

.nav-mobile .nav-faq .content{
  padding: 20px 40px;
}

.nav-mobile .nav-faq .content p{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #474D66;
  margin-bottom: 15px;
}

.nav-mobile .nav-faq .content button{
  width: 100%;
  padding: 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 27px;
}

.nav-mobile .nav-faq .content button .icon-whatsapp{
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.mobile-address{
  background: #fff;
  padding: 20px;
  position: relative;
  top: -13px;
  z-index: 5;
}

.tab-temp{
  width: 100%;
  max-width: 720px;
}

.container-404 .coll{
  max-width: 600px;
  width: 100%;
  background: #fff;
  padding: 80px 60px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
  border-radius: 8px;
  text-align: center;
}

.container-404 .coll h2{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #00AD9D;
  margin-top: 35px;
}

.container-404 .coll p{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #474D66;
  margin: 25px 0 45px 0;
}

.container-404 .coll button{
  max-width: 310px;
  width: 100%;
}

.container-404 .coll button .icon-voltar{
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.container-404 .coll button .icon-voltar path{
  fill: #fff;
}


/*Rating Star*/
.rating-star .rating-title{
  margin-top: 20px;
  text-align: center;

  font-family: Montserrat !important;
  font-size: 16px;
  font-weight: 700;
  color: #464F53;
}

.rating-star .react-simple-star-rating{
  direction: 'ltr';
  font-family: Montserrat !important;
  touch-action: 'none';
  margin: 15px 0;
}

.rating-star .react-simple-star-rating .empty-icons,
.rating-star .react-simple-star-rating .empty-icons svg,
.rating-star .react-simple-star-rating .empty-icons svg path{
  color: #e6e8f0;
}

.rating-star .react-simple-star-rating .filled-icons,
.rating-star .react-simple-star-rating .filled-icons svg,
.rating-star .react-simple-star-rating .filled-icons svg path{
  color: #FFC43E;
}

.rating-star .react-simple-star-rating .empty-icons svg,
.rating-star .react-simple-star-rating .filled-icons svg{
  margin: 0 5px;
}

.rating-star .rating-legenda{
  display: flex;
  justify-content: space-between;
  max-width: 213px;
  width: 100%;
  margin: 0 auto 15px;
}

.rating-star .rating-legenda span{
  font-size: 14px;
  color: #74848B;
  /* color: #8F95B2; */
  font-weight: 400;
}

.rating-star .star-title{
  text-align: center;
  /* color: #8F95B2; */
  color: #74848B;
  font-weight: 400;
}

.rating-star .rating-border{
  height: 2px;
  background: #EDF8F7;
  margin: 25px 0;
}

.feedback-h3 {
  font-size: 16px;
  color: #464F53;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
}

.rating-star .perguntas{
  display: block;
  text-align: center;
}

.rating-star .perguntas .item{
  width: auto;
  padding: 15px 0;
  margin-bottom: 10px;
  border-radius: 10px;
  background: var(--neutral-100, #F0F3F5);
  font-size: 16px;
  font-weight: 700;
  color: #464F53;
  cursor: pointer;
}

.rating-star .perguntas .item:hover,
.rating-star .perguntas .item.active{
  background: var(--primary-100, #EDF8F7);
  color: #01988C;
}

.text-obr{
  margin: 15px 0;
  color: #6B9539;
  font-size: 20px;
}

.text-aval{
  font-size: 14px;
  color: #74848B;
}

.rating-people .p{
  text-align: center;
  margin: 25px 0 15px 0;
  font-size: 16px;
  color: #464F53;
}

.rating-star,
.rating-people .rating-star{
  display: block;
  text-align: center;
}

.rating-people .rating-star .star-title{
  /*height: 18px;*/
  padding-bottom: 15px;
  /* color: #8F95B2; */
  color: #74848B;
  font-weight: 400;
}

.feedback-label{
  font-size: 16px;
  color: #474D66;
}

.tab-nav{
  width: 65vw;
  /*height: 96px;*/
  padding: 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #E0E7EB;
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
}

.tab-nav span{
  font-weight: 700;
  font-size: 14px;
  color: #474D66;
  padding: 20px;
  margin-right: 10px !important;
}

.tab-nav span[aria-selected="true"],
.tab-nav span[aria-selected="true"]:focus,
.tab-nav span:hover{
  color: #01988C !important;
  border-radius: 10px;
  background: #EDF8F7;
}

.tab-nav span[aria-selected="false"]{
  color: #474D66 !important;
}

.tab-nav span::before{
  background-color: inherit;
}

.documentos-temporaria-dialog .row-documents .flex input, .documentos-temporaria-dialog .row-documents .flex select, .documentos-temporaria-dialog .row-documents .flex button{
  height: 48px;
}

.documentos-temporaria-dialog .row-documents .ub-b-top-clr_3366FF {
  margin-right: 3px;
  width: 100%;
  max-width: 60px;
  font-weight: 600;
  font-size: 14px;
  background: #01988C;
  border: 1px solid #01988C;
  border-radius: 10px;
}

.documentos-temporaria-dialog .row-documents .ub-b-top-clr_3366FF:hover{
  opacity: 0.8;
}

.documentos-temporaria-dialog .row-documents .ub-b-lft-clr_D14343 {
  width: 60px;
  border-radius: 10px;
}

/****************************************************************************************************************************************/
/*Mobile*/
@media (max-width: 600px) {
  /*body{
    background: #fff;
  }*/

  .btn-acess-temp{
    padding: 23px 0;
  }

  .acesso-container{
    background: #fff;
  }

  .form-acesso {
    background: #fff;
    box-shadow: none;
  }

  .btn-recibo-2{
    padding: 23px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
  }

  .btn-recibo-2-mobile{
    padding: 23px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
  }

  /*Acompanhamento do Status*/
  .row-acp{
    /*height: 100vh;*/
    padding: 0;  
    width: 100%;
    max-width: inherit;
    position: relative;
    padding-bottom: 100px;
  }  

  .row-acp .coll {
    /*padding-bottom: 100px;*/
    background: #E6E8F0;
    box-shadow: none;
    padding: 20px 20px 0 20px;
  }

  .row-acp .coll .content{
    width: 200px;
  }
  
  .row-acp .coll .info{
    width: auto;
    display: block;
  }
  
  .row-acp .coll .info .imovel{
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .row-acp .coll .info-user{
    width: 300px;
  }

  .row-acp .container-info .row .coll-status .step-status{
    padding: 20px;
  }

  .row-acp .container-info .row .coll-status .step-status ul{
    margin-top: 0;
    display: block;
  }

  .row-acp .container-info .row .coll-status .step-status ul li{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .row-acp .container-info .row .coll-status .step-status ul li .step{
    margin: 0;
  }

  .row-acp .container-info .row .coll-status .step-status ul li span{
    text-align: left;
    margin: 0 0 0 15px;
  }

  .row-acp .container-info .row .coll-status .header .btn-feedback{
    padding: 23px;
    width: 100%;
    max-width: inherit;
  }

  .row-acp .container-info .row .coll-historico h2{
    display: none;
  }

  .row-acp .container-info .row .coll-historico ul li{
    border-bottom: 1px solid #D8DAE5;
    padding: 20px;
    display: block;
    margin-bottom: 0;
  }

  .row-acp .container-info .row .coll-historico ul li .badge{
    margin: 15px 0;
  }

  .row-acp .container-info{
    padding: 20px;
    margin-bottom: 130px;
  }

  .row-acp .container-info .row{
    background: #fff;
    padding: 0;
    /* Elevation / 1 */
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;
    margin-top: 20px;
  }

  .row-acp .container-info .row .coll-status .header{
    border-bottom: 1px solid #D8DAE5;
    padding: 20px;
  }

  .row-acp .container-info .row ul li{
    list-style: none;
  }

  .row-acp .container-info .row .coll-ajuda{
    padding: 20px;
  }

  .row-acp .container-info .row .coll-ajuda .content{
    display: block;
  }

  .row-acp .container-info .row .coll-ajuda .content .btn-contato{
    max-width: inherit;
    padding: 23px;
    font-size: 16px;
    border-radius: 10px;
    padding: 27px;
  }

  .row-acp .container-info .row .coll-ajuda .btn-ajuda{
    display: none;
  }

  .row-acp .container-info .row .coll-ajuda .content .btn-contato .icon-whatsapp{
    width: 18px;
    height: 18px;
  }
  
  .ajuda-temporaria-dialog-mobile{
    margin-top: 15px;
    /*padding: 30px 20px 20px 20px;*/
    padding: 0;
    
    /* Neutral / N0 */
    background: #FFFFFF;
    
    /* Elevation / 1 */
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;
  }

  .ajuda-temporaria-dialog li{
    margin-bottom: 0;
  }

  .ajuda-temporaria-dialog-mobile h2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #474D66;
    padding: 30px 20px 0 20px;
  }

  .ajuda-temporaria-dialog-mobile ul li{
    border-bottom: 1px solid #D8DAE5;
    margin: 0;
  }

  .ajuda-temporaria-dialog-mobile ul li .title{
    padding: 20px 20px 10px 20px;
  }
  
  .ajuda-temporaria-dialog-mobile ul li .content{
    padding: 0 20px 20px 20px;
  }

  .row-acp .nav-mobile{
    position: fixed;
    bottom: 0;
    display: block;
    background: #fff;
    width: 100%;
    border: 1px solid #D8DAE5;
  }
  .row-acp .nav-mobile ul{
    display: flex;
    justify-content: space-evenly;
    padding: 15px 0 0 0;
  }
  .row-acp .nav-mobile ul li{
    list-style: none;
    cursor: pointer;
    padding: -1px 15px;
    width: 65px;
    font-size: 12px;
    text-align: center;
    height: 50px;
  }

  .icon-nav-mobile{
    display: block;
    width: 22px;
    height: 22px;
  }

  .row-acp .nav-mobile ul li svg {
    display: block;
    margin: 0 auto 5px;
  }
  .row-acp .nav-mobile ul li.nav-enable{
    border-bottom: 2px solid #00AD9D;
  }

  .row-acp .nav-mobile ul li .nav-item-enable,
  .row-acp .nav-mobile ul li .nav-item-enable svg path{
    fill: #00AD9D;
    color: #00AD9D;
    font-weight: 500;
  }  

  .coll-neutro{
    position: absolute;
  }

  .btn-sair{
    position: relative;
    top: 0;
    right: 0;
  }

  .container-404{
    background: #fff;
  }

  .container-404 .coll{
    box-shadow: none;
  }

  .container-404 .coll .btn-login{
    padding: 23px;
  }

  .documentos-temporaria-dialog{
    /* height: 100vh; */
    /* margin: 0;
    padding: 0;
    max-width: inherit;
    max-height: inherit;
    height: calc(100vh + env(safe-area-inset-bottom)); */
    width: 100vw;
    /* height: 100vh; */
    height: calc(100vh + env(safe-area-inset-bottom));
    max-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    max-width: 100vw;
  }

  .documentos-temporaria-dialog .row-documents img{
    display: none;
  }

  .line-box-doc{
    display: block;
    margin-bottom: 25px;
    height: 1px;
    background: #D8DAE5;
  }

  .documentos-temporaria-dialog .row-documents .ub-w_max-content{
    width: 100%;
  }

  .documentos-temporaria-dialog .row-documents .flex{
    display: block;
  }

  .documentos-temporaria-dialog .row-documents .flex input,
  .documentos-temporaria-dialog .row-documents .flex select,
  .documentos-temporaria-dialog .row-documents .flex button{
    height: 48px;
  }
  /* .documentos-temporaria-dialog .row-documents .flex button:last-child{
    width: 100px;
  } */

  .documentos-temporaria-dialog .row-documents .inputGreenSelect,
  .documentos-temporaria-dialog .row-documents .gabiarraSelectMenuDocs,
  .documentos-temporaria-dialog .row-documents .gabiarraSelectMenuDocs .inputDefaultSelect {
    width: 100%;
  }

  .documentos-temporaria-dialog .row-documents .evergreen-file-picker-root{
    margin: 10px 0;
    width: 100%;
  }

  .documentos-temporaria-dialog .row-documents .btn-adicionar{
    margin: 0;
    width: 100%;
    height: 36px;
  }

  .documentos-temporaria-dialog .row-documents .ub-w_280px {
    width: 145px;
  }

  .documentos-temporaria-dialog .row-documents .ub-b-top-clr_3366FF{
    margin-right: 3px;
    width: 100%;
    max-width: 130px;
    font-weight: 600;
    font-size: 14px;
    background: #01988C;
    border: 1px solid #01988C;
    border-radius: 10px;
  }

  .documentos-temporaria-dialog .row-documents .ub-b-lft-clr_D14343{
    width: 60px;
    border-radius: 10px;
  }

  .documentos-temporaria-dialog .row-documents .row-btn{
    margin-bottom: 25px;
  }

  .documentos-temporaria-dialog .row-content{
    margin-bottom: 135px;
  }

  .documentos-temporaria-dialog .btn-save{
    /* position: absolute;
    bottom: 0;
    left: 5%;
    width: 90%;
    z-index: 9999; */
    width: 90%;
  }

  .documentos-temporaria-dialog .row-desktop{
    display: none;
  }

  .documentos-temporaria-dialog .row-mobile{
    display: block;
  }

  .documentos-temporaria-dialog .btn-save{
    margin: 15px 0 40px 0;
  }

  .documentos-temporaria-dialog .row-btn-save{
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #D8DAE5;
    background: #fff;
    left: 0;
    display: flex;
    justify-content: center;
    padding-bottom: env(safe-area-inset-bottom);
  }

  .row-acp .container-info .row-3{
    padding: 20px 20px;
    margin-bottom: 10px;
  }

  .row-acp .container-info .row-3 .rowForm{
    /*padding: 0 20px;*/
    display: block;
    margin-bottom: 20px;
  }

  .row-acp .container-info .row-3 h2{
    padding: 20px 0;
    margin: 0;
  }

  .btn-update-user{
    display: none;
  }

  .position-check{
    display: block;
    position: relative;
    left: 0;
    top: 0;
  }

  .row-acp .container-info .row-3 .rowForm div{
    max-width: initial;
  }

  .row-acp .container-info .row-3 .rowForm div .react-tel-input .form-control{
    width: 100%;
  }

  .documentos-temporaria-dialog .row-documents .gap8{
    margin: 0;
  }

  .documentos-temporaria-dialog .row-documents .gap8 span{
    margin: 0;
    font-weight: 500;
  }

  .documentos-temporaria-dialog .custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 18px;
  }
  
  /* .documentos-temporaria-dialog .custom-select::after {
    content: '▼';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: #333;
    pointer-events: none;
  } */

  .delete-doc-dialog button{
    border-radius: 10px;
    height: 48px;
    font-size: 14px;
  }

  .delete-doc-dialog button.ub-bg-clr_white{
    border: none;
  }

  .delete-doc-dialog button.ub-bg-clr_white:focus{
    box-shadow: none;
  }

  .cardAcessoVendedor .fontGreen{
    width: 200px;
  }

  .delete-doc-dialog .dialog-title-delete{
    width: 100%;
    margin-right: 51px;
    max-width: 235px;
  }

  .delete-doc-dialog h4{
    margin-right: 50px;
  }
}