
@media only screen and (max-width: 1260px) {
  .headerSideMenu {
    flex-direction: column;
  }
  .titleSideMenu {
    margin-top: 16px;
  }
}

.sideMenu {
  width: calc(8vw + 100px);
  height: 100vh;
  background-color: #dbdbdb;
  position: fixed;
}

.css-inrqm5[aria-selected="true"]:focus {
  box-shadow: none;
}

.ml202 {
  margin-left: calc(8vw + 100px);
}

.ml20 {
  margin-left: 20px;
}

.flex-center {  
  display: flex;
  align-items: center;
}

.titleSideMenu {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}

.justifyBetween {
  justify-content: space-between;
}

.pb20 {
  padding-bottom: 20px;
}

.vw80 {
  width: 90vw;
  display: table;
}

.rodapePainel {
  margin-left: 3vw;
  position: absolute;
  bottom: 0;
  left: 0;
}

.userCard {
  margin-top: 40px;
  margin-right: 10px;
  width: 174px;
  height: 202px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background: rgba(249, 249, 249, 0.5);  
  border-radius: 8px;
  box-shadow: 0 0 1px rgb(67 90 111 / 30%), 0 5px 8px -4px rgb(67 90 111 / 47%);
}

.userCard:hover:not(.addCard) {
  border: 1px solid rgb(67 90 111 / 30%);
  box-shadow: 0 0 1px rgb(67 90 111 / 50%), 0 5px 8px -4px rgb(67 90 111 / 77%);
}

.userCard span.title {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.textOverflow {  
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ml20 {
  margin-left: 20px;
}

.mxW400 {
  max-width: 400px;
}

.animationConstruction { 
  max-width: calc(72vw);
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: calc(10vw + 100px);
}

.mt30vh {
  margin-top: 30vh;
}

.addCard {
  padding-top: 5px;
  border: 1px solid #0832DE;
}

.addCard:hover svg {
  fill:#0832DE;
}

.addCard:hover span {
  color:#0832DE;
  font-weight: 600;
}

.headerUsers {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 40px;
}

.addUser div.headerUsers {
  justify-content: start;
}

.switchStatus {
  display: flex;
  flex-direction: row;
}

.headerUsers span.nameUser {
  margin-left: 20px;
  cursor: pointer;
  padding: 10px;
}

.table {
  flex-grow: 0 !important;
  font-size: 10px;
}

.table.name {  
  min-width: 200px;
  cursor: pointer;
  font-weight: 400 !important;
}

.table.filter {  
  min-width: 200px;
}

.flexBasic215 {
  flex-basis: 215px;
  min-width: 100px;
}

.mt20 {
  margin-top: 20px !important;
}

.w20 {
  max-height: 40px;
  width: 100%;  
}

.w20 .country-list {  
  font-size: 12px;
}

.w20 input.form-control {
  max-height: 20px;
  width: 100%;
  font-size: 12px;
}

.lineH {
  width: 120px;
  height: 0px;
  border: 1px solid #C4C4C4;
  transform: rotate(90deg);  
}

.filters {
  margin-top: 25px;
  margin-bottom: 15px;
}

.headerUsers span.title {
  font-size: 64px;
  font-weight: 500;  
  margin-bottom: 20px;  
}

.headerUsers div.counts {  
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.addCard:hover {
  fill: #0832DE;
  cursor: pointer;
  box-shadow: 0 0 1px rgb(67 90 111 / 50%), 0 15px 18px -14px rgb(67 90 111 / 77%);
}

.img-pb img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}