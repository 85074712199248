@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

/*Global.*/
* {
  padding: 0;
  margin: 0;
  font-family: Montserrat !important;
  font-weight: 500;
  color: #474D66;
}

.notAallowed, .notAallowed :is(input, button) {
  cursor: not-allowed !important;
}

.whiteBox{
  margin-top: 24px;
  margin-bottom: 24px;
  width: 451px;
  border-top: 1px solid #A3E6CD;
  background: #FFF;
  padding: 15px 24px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.whiteBox span {
  color: #696F8C;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;  
}

.whiteBox .count {
  color: #429777;  
  font-size: 24px;  
  font-weight: 600;
  line-height: 32px;
}

.ft16 {
  font-size: 16px;
}

.ft12 {
  font-size: 12px;
}

.ft10 {
  font-size: 10px;
}

.pulsar{
  position: relative;
  right: 5px;
  top: -8px;
}
.pulsar div,
.pulsar div > div{
  background-color: #D14343;
  opacity: 0.9;
}

.navbar {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 68px;
  left: 0px;
  top: 0px;
  padding: 12px 22px;
  background-color: white;
  align-items: center;
}

.mt68 {
  margin-top: 68px;
}

.mr8 {
  margin-right: 8px;
}

.redefinirSenha .ub-bs_kymczs {  
  box-shadow: 0 4px 8px rgb(16 24 64 / 0.08) !important;
}

input[type=checkbox].checklist  {
  transform: scale(1.2);
}

.ub-color_D14343 {
  white-space: normal !important;
}

.riscado {
  text-decoration: line-through;
}

.textCenter {
  text-align: center;
}

.inputContador::-webkit-outer-spin-button,
.inputContador::-webkit-inner-spin-button  {
  -webkit-appearance: none !important;
}

.revisao,
.solicitar-nucleo {
  font-size: 24px;
  color: #474D66;
  font-weight: 600;
  display: inline-block;
  max-width: 305px;
  width: 100%;
  line-height: 32px;
}

.solicitar-nucleo {
  max-width: inherit;
  margin-bottom: 5px;
}

.badge-solciitacao-nucleo {
  margin-top: 35px;
  margin-bottom: 0;
  margin-left: 0;
}

.footer-dialog-nucleo {
  display: flex;
  justify-content: flex-end;
  padding: 35px 0;
}

.backgroundLoadingDocs {
  width: 100%;
  height: 400px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
  color: #c1c4d6 !important;
  border-color: #E6E8F0 !important;
}

.btnDisable {
  cursor: not-allowed !important;;  
  background-color: #D6E0FF !important;;
  border-color: #D6E0FF !important;;
}

.btnGreenDisable {
  cursor: not-allowed !important;;  
  background-color: #DCF2EA !important;;
  border-color: #DCF2EA !important;;
}

.card {
  width: 271px;
  height: 64px;
  background: #F9FAFC;
  border-radius: 4px;
  border: 1px solid #C1C4D6;
  
}

.card-enable {
  animation: card_enable 1s 1 normal;
}

.card-hidden {
  animation: card_hidden 1s 1 normal;
}

.dc_dash_table {  
  border-left: 1px solid #E6E8F0;
  text-align: center;
}

.cardAcessoVendedor {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  text-align: center;
  background: white;
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
  border-radius: 8px;
  width: inherit;
  height: 186px;
}

@keyframes card_hidden {
	from {
		transform: translateX(0px);
	}
	to {
    transform: translateX(100px);
	}
}

@keyframes card_enable {
	from {
		transform: translateX(-30px);
	}
	to {
		transform: translateX(0px);
	}
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 0px 12px;
  font-size: 10px;
  color: #474D66;
  text-transform: uppercase;
}

.cardText {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding: 0px 8px 7px 12px;
  max-width: 200px;
}

textarea {
  font-family: Montserrat;
  color: #696F8C;
}

textarea,
a {
  font-weight: 400;
}

.container {
  width: 100%;
  height: 100vh;
}

.painel {
  padding: 20px;
}

/*Header*/
.title {
  font-size: 1.5rem;
  font-weight: 600;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.subtitle-table {
  margin-top: 30px;
}

.content-click {
  cursor: pointer;
}

button {
  font-family: Montserrat;
}

/*Alert*/
.alert {
  max-width: 617px;
  margin-top: 30px;
  position: relative;
}

.alert .btn-close {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

.alert .alert-info {
  margin-bottom: 0;
}

.alert h4 {
  color: #2952CC;
}

.badge-tipo-pesso {
  margin-top: 35px;
}

.badge-tipo-pessoa span {
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;

  letter-spacing: 0.2px;
  text-transform: uppercase;

  color: #317159;
}

/*DNA Color Buttons*/
.btn-dna-green{
  background: #5EA99B !important;
  border: 1px solid #5EA99B !important;
  color: #fff !important;
}

.green {
  fill: #5EA99B !important;
}

.lightGrayFill {
  fill: #E6E8F0 !important;
}

.btn-dna-green:hover{
  background: #22806E !important;
  border: 1px solid #22806E !important;
}

.btn-dna-yellow{
  background: #11DC6E !important;
  border: 1px solid #11DC6E !important;
  color: #fff !important;
}

.btn-dna-yellow:hover{
  background: #11DC6E !important;
  border: 1px solid #11DC6E !important;
  opacity: 0.8;
} 
/*******************/

/*Button*/
button .icon-primary-button {
  fill: #fff;
  margin-left: 8px;
  width: 12px;
  height: 12px;
  color: #fff;
}

button.default-button {
  background: #FFFFFF;
  border: 1px solid #D8DAE5;
  box-sizing: border-box;
  border-radius: 4px;
  color: #696F8C;
}

button.default-button:hover {
  color: #474D66
}

.gap20 {
  gap: 20px;
}

.gap24 {
  gap: 24px;
}

.gap18 {
  gap: 18px;
}

.gap16 {
  gap: 16px;
}

.gap15 {
  gap: 15px;
}

.gap5 {
  gap: 5px;
}

.end {
  align-items: end;
}

.gabiarraSelectMenuDocs {
  border: none;
  cursor: pointer !important;
}

/*Filter Table*/
.filter-table {
  display: flex;
  align-items: end;
  padding-bottom: 15px;
  /* display: none; */
}

.space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.filter-table div[role=combobox] {
  width: auto;
  margin-right: 10px;
}

.filter-table input {
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
  color: #474D66;
}

.filter-table .filter-field,
.filter-table div[role=combobox] .ub-dspl_flex,
.filter-table div[role=combobox] .css-l6zppx> :first-child:not(:last-child) {
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
  color: #474D66;
}

.filter-table .filter-field:nth-child(3) {
  margin-left: 10px;
}

.filter-table .filter-field .filtro-select select {
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
  border-color: #D8DAE5;
  background-color: white;
}

.color-select-filter select {
  color: #474D66;
}

.color-select-filter-hidden select {
  color: #8F95B2;

}

.filter-table span {
  display: block;
  margin-right: 15px;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
  color: #474D66;
}

.filter-info {
  background: #fff;
  border-bottom: 1px solid #E6E8F0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-info p,
.filter-info span {
  font-weight: 500;
  font-size: 12px;
  color: #696F8C;
}

.filter-info span {
  display: inline;
  color: #3366FF;
  font-family: Montserrat;
  cursor: pointer;
}

.filter-info .icon-filter {
  fill: #8F95B2;
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
}


/*Tables*/
.table .ub-fnt-fam_b77syt {
  font-family: Montserrat;
}

/* // COORDENAÇÃO POS VENDA */
.collapse-coord {
  background: red;
  overflow: hidden;
  max-height: 0px;
  height: 0px;
  transition: max-height 0.3s ease-in-out;
}
.table-coordpos {
  width: 13vw;
  border-right: 1px solid #E6E8F0;
}
.table-coordpos span {
  font-size: 10px;
}
.coordpos-andamento {
  width: 13vw;
  border-right: 1px solid #E6E8F0;
}
.coordpos-andamento span {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: #429777;
}
.coordpos.header span {
  font-size: 10px;
}

.coordpos.body {
  cursor: pointer;
}
.coordpos.body .avatar span {
  font-size: 12px;
}
.coordpos.body span {
  font-size: 16px;
}
.coordpos.responsavel div {
  display: flex;
  align-items: center;  
}
.coordpos.responsavel {
  width: 18vw;
  border-right: 1px solid #E6E8F0;  
  flex: none;
}
.coordpos.total {
  border-right: 1px solid #E6E8F0;
  background: #F5FBF8;
  text-align: center;
  width: 7vw;
  padding: 0;
  flex: none;
}
.coordpos.body.total span div {
  color: #429777;
}
.coordpos.a_vista {
  text-align: center;
  width: 7vw;
  padding: 0;
  flex: none;
}

.coordpos.financiado {
  text-align: center;
  width: 11vw;
  padding: 0;
  flex: none;
}

.coordpos.fgts {
  text-align: center;
  width: 7vw;
  padding: 0;
  flex: none;
}

.coordpos.consorcio {
  text-align: center;
  width: 7vw;
  padding: 0;
  flex: none;
}

.coordpos.header.em_dia span {
  color: #3366FF;
  font-weight: 500;
}
.coordpos.em_dia {
  text-align: center;
  background: #F3F6FF;
  width: 7vw;
  padding: 0;
  flex: none;
}
.coordpos.body.em_dia span div {
  color: #3366FF;
  font-weight: 500;
}

.coordpos.header.alerta span {
  color: #FFB020;
  font-weight: 500;
}
.coordpos.alerta {
  text-align: center;
  background: #FFFAF1;
  width: 7vw;
  padding: 0;
  flex: none;
}
.coordpos.body.alerta span div {
  color: #FFB020;
  font-weight: 500;
}

.coordpos.header.atrasado span {
  color: #D14343;
  font-weight: 500;
}
.coordpos.atrasado {
  text-align: center;
  background: #FDF4F4;
  width: 7vw;
  padding: 0;
  flex: none;
}
.coordpos.body.atrasado span div {
  color: #D14343;
  font-weight: 500;
}
/* // FIM COORDENAÇÃO POS VENDA */

.table .header-title span {
  color: #696F8C;
  font-weight: 500;
  font-size: 10px;
}

.table .content-endereco span {
  color: #101840;
  font-weight: 400;
}

.table .content-nome span {
  color: #696F8C;
  font-weight: 400;
}

.table span .content-tag {
  color: #3366FF;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  padding: 10px;
  background-color: #F3F6FF;
  border: 1px solid #D8DAE5;
  border-radius: 5px;
  white-space: pre-line;
}

.table span .content-tag span.circleIcon {
  width: 6px;
  height: 6px;
  background-color: #52BD94;
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

/* // TABLE PRINCIPAL APOIO */
.apoioTest {
  /* min-width: 9vw; */
}

.table.apoio_resp {
  min-width: 64px;
}

.table.apoio_endereco {
  min-width: 23vw;
}

.table.apoio_comissao {
  min-width: 10vw;
}

.table.apoio_fechamento {
  min-width: 9vw;
}

.table.apoio_vgv {
  min-width: 11vw;
}

.table.apoio_gerente {
  min-width: 11vw;
}

.table.apoio_data {
  min-width: 100px;
}

.table.apoio_hora {
  min-width: 60px;
}

.table.apoio_badge {
  min-width: 10vw;
}

.table.apoio_tools {
  min-width: 0px; 
}

/*Dialog Share*/
.dialog-content {
  color: #696F8C;
}

.dialog-content-rua {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #474D66;
}

.dialog-content-info {
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: 400;
}

.mensagem{
  color: #696F8C;
  word-break: break-all;
}

.mensagem-completa{
  white-space: normal;
  word-break: break-all;
}

/* .form-dialog .ub-flx_1 {
  margin-bottom: 10px;
} */

.dialogSubTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

/*Steps*/
.steps {
  display: flex;
  position: absolute;
  top: 25px;
}

.steps .step-item {
  margin-right: 15px;
}

.steps .step-item span {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  font-size: 10px;
}

.steps .step-item span.etapa {
  font-weight: 700;
  font-size: 12px;
  color: #696F8C;
}

.steps .step-item span.default {
  color: #696F8C;
  font-size: 10px;
}

/* .steps .step-item span.active {
  color: #3366FF;
  font-size: 10px;
} */

.steps .step-item .badge-default {
  color: #696F8C;
  background-color: #EDEFF5;
  font-size: 10px;
}

.steps .step-item .badge-active {
  color: #2952CC;
  background-color: #D6E0FF;
  font-size: 10px;
}

.steps .step-item .badge-check {
  background-color: #DCF2EA;
  padding: 0 2px;
}

.steps .step-item .step-check {
  fill: #52BD94;
}

.steps-lista {
  top: 0;
  position: inherit;
}

.progress-line {
  position: relative;
  top: 8px;
  align-items: center;
  background-color: #E6E8F0;
  height: 2px;
  width: 60px;
}

.progress-line .total-progress {
  background-color: #52BD94;
  height: 2px;
  width: 20px;
}

.progress-line .progress-step-10,
.progress-line .progress-step-12,
.progress-line .progress-step-13,
.progress-line .progress-step-14 {
  width: 0px;
}

.progress-line .progress-step-11,
.progress-line .progress-step-15 {
  width: 20px;
}

.progress-line .progress-step-16,
.progress-line .progress-step-17,
.progress-line .progress-step-18,
.progress-line .progress-step-19 {
  width: 40px;
}

.progress-line .progress-step-20 {
  width: 60px;
}

/*Forms*/
.formGroup {
  padding-left: 10px;
}

.formGroup .subtitle {
  padding-left: 0;
}

.formGroup label {
  font-weight: 400;
  font-size: 14px;
  font-family: Montserrat;
  color: #474D66;
}

.formGroup .checkox-label {
  margin-bottom: 0;
}

.formGroup input,
.formGroup select {
  font-weight: 400;
  font-size: 12px;
  font-family: Montserrat;
  color: #696F8C;
}

.rowForm {
  display: flex;
  flex: 1;
}

.rowForm div:first-child {
  margin-right: 20px;
  /*margin-bottom: 0;*/
}

.form-dialog .rowForm-responsavel-pj .origem-pj div:last-child {
  margin-top: 5px
}

.novo-documento-vendedor .rowForm div:first-child,
.novo-documento-comprador .rowForm div:first-child {
  margin-right: 10px;
}

.novo-documento-vendedor .rowForm .documento-vendedor .evergreen-file-picker-text-input,
.novo-documento-comprador .rowForm .documento-comprador .evergreen-file-picker-text-input {
  width: 120px;
}

.rowForm-responsavel-pj .row-documento-representante .rowForm-documentos .evergreen-file-picker-text-input {
  width: 161px;
}

.representante-uniao-estavel {
  align-items: end;
  position: relative;
  top: -10px;
}

.upload-obrigatorio div:first-child,
.upload-espelho-venda div:first-child,
.upload-documentos div:first-child {
  margin-bottom: 0;
  height: 0;
}

.input-obrigatorio::placeholder {
  color: #696F8C;
}

.upload-obrigatorio,
.documento-obrigatorio {
  margin-bottom: 2px;
}

.form-dialog .documento-obrigatorio {
  margin-bottom: 0;
}

.field-select {
  margin-top: 4px;
}

.line-checkbox {
  display: flex;
}

.line-checkbox .checkbox {
  margin-right: 15px;
}

.line-checkbox .checkbox span {
  display: block;
  font-size: 12px;
  font-family: Montserrat;
}

.rowForm-responsavel-pj {
  border: 1px solid #D8DAE5;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;
}

.rowForm-documentos {
  display: flex;
}

.rowForm-responsavel-pj .line-checkbox .checkbox span {
  font-size: 14px;
}

.line-checkbox .checkbox svg path,
.checkbox svg path {
  color: #fff;
}

.line-checkbox .checkbox-disabled span {
  color: #8F95B2;
}

.line-checkbox .checkbox-disabled svg path {
  color: #C1C4D6;
}

.line-radius .radius {
  display: flex;
  flex-wrap: wrap;
}

.line-radius .radius label {
  margin-right: 15px;
}

.line-radius .radius svg circle {
  color: #fff
}

.radio-genero span {
  color: #474D66;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}

.area-recibo-sinal {
  margin-bottom: 25px;
  /*border-top: 1px solid #d8dae5;
  border-bottom: 1px solid #d8dae5;*/
  padding: 0px 0
}

.area-recibo-sinal .title-doc {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  font-family: Montserrat;
  color: #101840;
  margin-right: 5px;
}

.doc-name,
.area-recibo-sinal .content-doc {
  font-size: 12px;
  display: inline-block;
  color: #3366FF;
  cursor: pointer;
}

.doc-name .name-ref {
  font-weight: 400;
  display: inline-block;
  font-size: 14px;
  color: #696F8C;
  margin: 0 10px 15px 5px;
}

.doc-name .link-ref {
  font-weight: 500;
  display: inline-block;
  font-size: 14px;
  color: #3366FF;
  text-decoration: underline;
}

a .iconLink {
  fill: #8F95B2;
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

.mensagem-form {
  font-weight: 400;
  font-size: 14px;
  color: #696F8C;
}

.field-parte-pagamento {
  position: relative;
  top: 14px;
}

.rowForm .field-parte-pagamento div {
  margin-right: 0;
}

.row-laudemio .line-radius {
  max-width: 280px;
  width: 100%;
}

.revisao-minuta,
.revisao-minuta .termo-ciencia {
  border: 1px solid #D8DAE5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px;
}

.revisao-minuta .titulo-revisao-minuta {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 15px;
}

.revisao-minuta .conteudo-revisao-minuta p {
  margin-bottom: 15px;
  font-family: Montserrat;
  font-size: 13px;
  color: #474D66;
  line-height: 19px;
}

.revisao-minuta .conteudo-revisao-minuta p .destaque {
  font-weight: 700;
}

.revisao-minuta .termo-ciencia,
.revisao-minuta .data-contrato {
  text-align: center;
}

.revisao-minuta .conteudo-revisao-minuta .data-contrato {
  margin-top: 30px;
  margin-bottom: 25px;
}

.revisao-minuta .assinaturas,
.revisao-minuta .testemunhas-observacao {
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
}

.revisao-minuta .testemunhas-observacao {
  margin-bottom: 80px;
}

.revisao-minuta .assinaturas {
  display: flex;
  max-width: 900px;
  width: 100%;
  flex-wrap: wrap;
}

.revisao-minuta .assinaturas div {
  width: 250px;
}

.revisao-minuta .assinaturas p {
  margin-bottom: 5px;
  text-align: center;
}

.revisao-minuta .testemunhas-observacao .testemunhas {
  width: 250px;
}

.revisao-minuta .testemunhas-observacao .observacao {
  width: 230px;
}

.revisao-minuta .testemunhas-observacao .testemunhas p {
  margin-bottom: 5px
}

.revisao-minuta .testemunhas-observacao .observacao {
  border: 1px solid #D8DAE5;
  padding: 10px
}

.revisao-minuta .testemunhas-observacao .observacao p span {
  font-weight: 700;
}

.ilustra-dialog {
  display: block;
  margin: 0 auto 15px;
}

/*Campos sem input*/
.formGroup .show-laudemio .subtitle {
  margin-bottom: 15px
}

.formGroup .show-laudemio ul {
  margin-bottom: 15px;
}

.formGroup .show-laudemio ul li {
  list-style: none;
  display: flex;
  text-align: left;
}

.formGroup .show-laudemio ul li .tipo-laudemio {
  margin-right: 50px;
  width: 70px;
}

.formGroup .show-laudemio ul li .tipo-laudemio .titulo span,
.formGroup .show-laudemio ul li .valor-laudemio .titulo span,
.formGroup .show-estado-civil .titulo {
  font-weight: 400;
  color: #101840;
  font-size: 14px;
  font-family: Montserrat;
  display: block;
  margin-bottom: 10px;
}

.formGroup .show-laudemio ul li .tipo-laudemio .conteudo,
.formGroup .show-laudemio ul li .valor-laudemio .conteudo,
.formGroup .show-estado-civil .conteudo {
  font-weight: 400;
  color: #696F8C;
  font-size: 14px;
  font-family: Montserrat;
  display: block;
  margin-bottom: 25px;
}

.formGroup .block-select select {
  pointer-events: none;
  cursor: default;
}

.formGroup .block-select svg {
  display: none;
}

.form-dialog-fixed,
.form-dialog-fixed-no-tabs {
  position: fixed;
  width: 100%;
  background: #ffff;
  left: 0;
  z-index: 9;
  top: 20px;
  height: 90px;
  border-bottom: 1px solid #E6E8F0;
}

.form-dialog-fixed-no-tabs {
  height: 40px;
}

.form-dialog-fixed .steps,
.form-dialog-fixed-no-tabs .steps {
  top: 0;
  padding: 0 30px;
}

.form-dialog-fixed .tab-form {
  padding: 0 30px;
  position: relative;
  top: 40px;
}

.form-dialog .row-select-servicos {
  display: flex;
  justify-content: space-between;
}

.form-dialog .row-select-servicos label,
.form-dialog .row-nucleo-observacao label {
  margin-bottom: 10px;
  display: block;
}

.form-dialog .row-select-servicos .select-servicos {
  display: inline-block;
}

.form-dialog .row-nucleo-observacao {
  margin-top: 10px;
}

.formGroup-dialog {
  position: relative;
  top: 60px;
}

.formGroup-no-tabs {
  top: 0;
}

.formGroup-dialog .msg-obrigatorio {
  margin-bottom: 150px;
}

.formGroup-no-tabs .msg-obrigatorio {
  margin-bottom: 90px;
}

.form-dialog .formGroup-dialog .footer-form {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 98%;
  border-top: 1px solid #E6E8F0;
}

.mb16 {
  margin-bottom: 16px;
}

.mb17 {
  margin-bottom: 17px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb28 {
  margin-bottom: 28px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb80 {
  margin-bottom: 80px;
}

.footerForm {  
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: calc(94.5% - 16px * 2);
  border-top: 1px solid #E6E8F0;
  padding: 20px 30px;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;  
}

.form-dialog .formGroup-dialog .footer-form div:first-child {
  padding-left: 30px;
}

.form-dialog .formGroup-dialog .footer-form div:last-child {
  padding-right: 30px;
}

.novo-laudemio .ub-mt_8px {
  margin-top: 0;
}

.inline-alert .ub-dspl_flex {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.inline-alert span {
  display: block;
  position: relative;
  top: 1px;
}

.inputSucess {
  border-color: #429777 !important;
  background: #F5FBF8;
}

.inputDefault {
  border-color: #ADC2FF;
  background: #fff;
}

.inputGreenSelect select,
.inputGreenSelect input,
.upload-recibo-doc-1 .inputGreenSelect input.evergreen-file-picker-text-input,
.inputGreenSelect button {
  border-color: #429777;
  background: #F5FBF8
}

.inputDefault,
.inputDefault select,
.inputDefault input,
.upload-recibo-doc-1 .inputDefault input.evergreen-file-picker-text-input,
.inputDefault button {
  border-color: #ADC2FF;
  background: #fff;
}

.inputErrorSelect,
.inputErrorSelect select,
.inputErrorSelect input,
.upload-recibo-doc-1 .inputErrorSelect input.evergreen-file-picker-text-input,
.inputErrorSelect button {
  border-color: #FF0000;
  background: '#fff';
}

/*Tab Detalhes*/
.detadelhes-sidesheet .historicoStatus,
.detadelhes-sidesheet .fontStatus,
.detadelhes-sidesheet .historicoStatus .mensagemStyle {
  padding: 0;
  flex: inherit;
}

/*Tab Historico*/
.row-historico {
  padding: 25px 0;
  /*height: auto;*/
}

.historicoStatus .mensagemStyle {
  margin-left: 10;
}

.historicoStatus .mensagemStyle .mensagem {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #474D66;
  padding-left: 18px;
  margin-top: 3px;
  display: block;
  /* Resolve bug do nowrap do bootstrap */
  white-space: normal;
}

.historicoStatus .mensagemStyle a {
  color: #3366FF;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-top: 3px;
  padding-left: 18px;
  text-decoration: none;
}

.historicoStatus .mensagemStyle a .iconLink {
  fill: #8F95B2;
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

.historicoStatus .iconTick,
.historicoStatus .iconDocument {
  position: relative;
  top: 3px;
  margin-right: 10px;
  fill: #8F95B2;
}

.historicoStatus .iconDocument {
  width: 17px;
  height: 17px;
}

.historicoStatus .fontStatus {
  font-size: 14px;
  font-family: 'Montserrat';
  margin-bottom: 3px;
  display: block;
  font-weight: 400;
  white-space: normal;
}

.historicoStatus .boldStatus {
  font-weight: 500;

}

/**/
.historicoStatus .historic-message .ub-wht-spc_nowrap{
  white-space: normal;
}

.historico-status-solicitacao {
  font-weight: 400;
  font-size: 14px;
  color: #474D66;
  margin: 10px 0;
}

.historico-mensagem-d-solicitacao {
  font-weight: 400;
  font-size: 12px;
  display: 'block';
  margin-top: 5px;
  margin-bottom: 0;
  display: block;
}

.historico-mensagem-solicitacao {
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  line-height: 20px;
}

/*Tab Núcleo*/
.tab-nucleo span {
  font-size: 14px;
  display: block;
  font-family: Montserrat;
}

.tab-nucleo .mensagem {
  line-height: 20px;
}

.tab-nucleo .fontStatus {
  margin-bottom: 10px;
}

/*Footer Form*/
.footerFormButtons {
  margin-top: 30px;
}

.footerFormButtons button {
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 600;
}

/*SideSheet*/
.side-sheet-click span {
  display: flex;
}

.user-list {
  max-width: 90px;
  padding: 0;
}

.user-list span {
  display: block
}

.share-disable-btn,
.tab-disable {
  pointer-events: none;
  opacity: 0.5;
  background-color: #D6E0FF;
}

.share-enable-btn,
.tab-enable {
  pointer-events: auto;
}

.tab-enable,
.tab-disable {
  border: none;
  background: #fff
}

.tab-enable:focus {
  box-shadow: none;
}

.side-sheet-click .btn-adicionar {
  background: #FFFFFF;
  border: 1px solid #D8DAE5;
  box-sizing: border-box;
  border-radius: 4px;
  color: #696F8C;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.row-documento {
  margin-bottom: 50px;
}

.row-documento h2 {
  font-size: 16px;
  margin: 35px 0 15px 0;
}

.btn-deletar-documento {
  top: 26px;
  left: 15px;
}

.btn-down-planilha{
  background: #FFFFFF;
  border: 1px solid #D8DAE5;
  border-radius: 4px;
  color: #696F8C !important;
}

.link-vendedor {
  margin-right: 15px
}

.mensagem-processo {
  color: #696F8C;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
  display: block;
  padding: 15px;
}

.mensagem-processo a {
  color: #3366FF;
  text-decoration: none;
  cursor: pointer;
}

/*Dialog & Corner Dialog*/
.dialog-confirm div h4 {
  color: #474D66;
  font-weight: 600;
  font-size: 24px;
  max-width: inherit;
}

.content-corner-dialog,
.content-dialog {
  font-weight: 400;
  font-size: 14px;
  color: #696F8C;
  line-height: 21px;
  display: block;
}


/*Form Dialog*/
.title-dialog{
  color: #474D66;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
  position: relative;
  top: -15px;
}

.label-dialog,
.form-status-escritura .rowForm label{
  color: #474D66 !important;
}

.form-status-escritura{
  border: 1px solid #D8DAE5;
  border-radius: 8px; 
  padding: 20px; 
  margin-bottom: 20px;
}

.form-dialog-props div h4,
.form-dialog .subtitle {
  color: #474D66;
  font-weight: 600;
  font-family: Montserrat;
}

.form-dialog-props div h4 {
  font-size: 24px;
  max-width: inherit;
}

.form-dialog-props div button.css-1tyjqqv {
  z-index: 99;
}

.form-dialog .subtitle {
  font-size: 16px;
  margin-bottom: 25px;
}

.form-dialog .subtitle .icon-subtitle {
  fill: #8F95B2;
  margin-right: 10px;
}

.form-dialog .descritption {
  font-weight: 400;
  font-size: 14px;
  color: #474D66;
  margin-bottom: 35px;
  display: block;
}

.form-dialog .row-recibo {
  margin-top: 25px;
}

.form-dialog .row-recibo .description-upload {
  width: 100%;
  max-width: 568px;
  line-height: 22px;
}

.form-dialog .row-recibo .subtitle {
  margin-bottom: 18px;
}

.form-dialog .row-recibo .label {
  margin: 0 0 15px 0;
  display: block
}

.label-upload {
  font-weight: 400;
  font-size: 14px;
  font-family: Montserrat;
  color: #474D66;
  margin-bottom: 12px
}

.checkox-uniao-estavel {
  font-weight: 400;
  font-size: 14px;
  color: #474D66;
  font-family: Montserrat;
  position: relative;
  top: 5px;
}

.form-dialog .footer-form {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.mensagem-campos,
.form-dialog .footer-form .mensagem-campos {
  font-weight: 400;
}

.msg-obrigatorio {
  margin-top: 15px;
}

.form-dialog .btn-default {
  font-weight: 500;
  font-size: 12px;
  font-family: Montserrat;
  color: #696F8C;
  border-color: #D8DAE5;
}

.form-dialog .footer-form .btn-minimal {
  border: none;
  font-weight: 500;
  color: #696F8C;
  font-family: Montserrat;
  cursor: pointer;
}

.form-dialog .footer-form .btn-minimal:hover {
  border: none;
  font-weight: 600;
}

.form-dialog .footer-form .btn-minimal-disabled {
  color: #D8DAE5;
  cursor: default;
}

.btn-add-laudemio-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.form-dialog .icon-save {
  fill: #fff;
  margin-left: 8px;
  width: 12px;
  height: 12px;
}

.form-dialog .icon-upload {
  fill: #fff;
  margin-right: 8px;
  width: 12px;
  height: 12px;
}

.form-dialog .icon-label {
  fill: #8F95B2;
  width: 13px;
  height: 13px;
  margin-left: 5px;
}

.form-dialog .icon-tick {
  fill: #fff;
  width: 13px;
  height: 13px;
  margin-left: 5px;
}

.form-dialog .rowForm .recibo-logradouro-1,
.form-dialog .rowForm .recibo-complemento-1 {
  margin-left: 20px;
}

.form-dialog .btn-add-documento {
  font-weight: 500;
  font-size: 12px;
  color: #696F8C;
  border: 1px solid #D8DAE5;
  margin-top: 10px;
  margin-bottom: 20px;
}

.form-dialog .btn-add-documento .icon-label {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.evergreen-file-picker-button {
  text-indent: -9999px;
  line-height: 0;
}

.evergreen-file-picker-button::after {
  content: 'Selecionar';
  text-indent: 0;
  display: block;
  line-height: initial;
}

.dialog-3-buttons {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 15px;
}

.disabled-upload {
  cursor: not-allowed;
  pointer-events: none;
}

.form-dialog .rowForm-responsavel-pj {
  border: 1px solid #D8DAE5;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 20px 20px 0;
}

.form-dialog .rowForm-responsavel-pj .rowForm {
  margin-bottom: 25px;
}

.form-dialog .rowForm-responsavel-pj .rowForm label {
  margin-bottom: 5px;
}

.form-dialog .rowForm-responsavel-pj .rowForm .line-checkbox label,
.form-dialog .rowForm-responsavel-pj .rowForm div,
.form-dialog .rowForm-responsavel-pj .rowForm div:first-child,
.form-dialog .rowForm-responsavel-pj .rowForm div:last-child {
  margin-bottom: 0;
  margin-top: 0;
}

.form-dialog .rowForm-responsavel-pj .rowForm div input {
  margin-bottom: 10px;
}

.form-dialog .rowForm-responsavel-pj .rowform-origem div {
  margin-bottom: 10px;
}

.error-genero,
.error-input {
  display: block;
  margin-bottom: 15px;
  color: #D14343;
}

.form-dialog .origem-pf div div:last-child {
  margin-top: 0;
}

.form-dialog .origem-pf div:last-child div:last-child {
  margin-top: 10px;
}

.rowForm-responsavel-pj .rowForm div:nth-child(1) div,
.rowForm-responsavel-pj .rowForm div:nth-child(2) div {
  margin-bottom: 10px;
}

.error-input span {
  color: #D14343;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: normal;
  position: relative;
  top: -1px;
}

.error-genero span {
  display: flex;
  color: #D14343;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: normal;
  position: relative;
  top: -1px;
  align-items: center;
  white-space: nowrap;
  /* retira quebra de texto */
}

.error-genero svg,
.error-input svg {
  width: 14px;
  height: 14px;
  fill: #D14343;
  margin-right: 7px;
}

/* msg de error do evergreen */
.ub-color_D14343 {
  white-space: nowrap;
}

.error-genero svg path,
.error-input svg path {
  margin-right: 7px;
  fill: #D14343;
  color: #D14343;
}

.icon-error-revisao,
.icon-tick-revisao {
  position: relative;
  top: 4px;
  margin-left: 10px;
}

.icon-error-revisao path,
.icon-component-revisao path,
.icon-error-validate path {
  fill: #D14343;
}

.icon-tick-revisao path,
.icon-component-tick-revisao path {
  fill: #52BD94;
}

.icon-component-revisao,
.icon-component-tick-revisao {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

/*Menu Continuar Vendas*/
.nav-rascunho-venda .tick-cicle-icon-menu svg {
  fill: #52BD94;
}

.nav-rascunho-venda .warning-icon-menu svg {
  fill: #FFB020;
}

.nav-rascunho-venda .lock-icon-menu svg {
  fill: #8F95B2;
}

.nav-rascunho-venda .nav-item {
  padding-left: 0;
  padding-right: 0;
}

/*Btn Entregar Venda*/
button.btn-disabled-entregar-venda {
  background-color: #DCF2EA;
  color: #fff;
  border: none
}

button.btn-disabled-entregar-venda[disabled] {
  color: #fff;
}

button.btn-enabled-entregar-venda,
button.btn-enabled-entregar-venda:not([disabled]):hover {
  background: #429777;
  color: #fff
}

button.btn-enabled-entregar-venda:not([disabled]):hover {
  opacity: 0.8;
}

/*Dialog Informacao*/
.form-dialog-info div:first-of-type {
  padding-bottom: 0;
}

.form-dialog-info .dialog-info .content-boletim {
  display: flex;
}

/*Change Log*/
.change-log-props div {
  padding-bottom: 0
}

.change-log .title {
  font-weight: 600;
  font-size: 32px;
}

.change-log .data {
  font-weight: 400;
  font-size: 14px;
  color: #8F95B2;
  margin-top: 5px;
}

.change-log img {
  display: block;
  margin: 35px auto 35px;
}

.change-log .row .badge {
  margin-bottom: 15px;
}

.change-log .row ul {
  padding-left: 20px;
  margin-bottom: 30px
}

.change-log .row ul li {
  font-weight: 400;
  font-size: 16px;
  color: #474D66;
  margin-bottom: 10px;
}

.change-log .line-checkbox .checkbox span {
  padding-bottom: 15px;
  font-size: 14px;
  font-family: Montserrat;
  display: block;
}

.css-lfowci:focus {
  box-shadow: none !important;
}

.css-lfowci[aria-selected="true"] {
  box-shadow: 0 0 0 2px #d6e0ff;
}

.dangerTab[aria-selected="true"] {
  background: #F9DADA !important;
  color: #D14343 !important;
}

.Rectangle35 {
  width: auto;
  height: 2px;
}

.css-kegsl2:focus {
  box-shadow: none !important;
}

.Neutral {
  background: #C1C4D6;
  margin-top: 20px;
}

.lightGray {
  background: #E6E8F0;
}

.Blue {
  background: #9DB5FF;
}

.Green {
  background: #429777;
}

.Red300 {
  background: #EE9191;
}

.css-9r9n92:not([aria-current="true"]):not([aria-checked="true"]):not(:focus):not(:active):hover {
  background-color: #F9FAFC !important;
}

/*Ver Detalhes*/
.side-sheet-detalhes .ub-ovflw-y_scroll div div {
  overflow-y: hidden;
}

.detalhes-doc {
  padding: 0;
}

.detalhes-doc li {
  list-style: none;
  margin-bottom: 15px;
}

.detalhes-doc li .link-doc {
  cursor: pointer;
  color: #3366FF;
}

/* Botão Sininho*/

.noti-title{
  margin-left: 10px;
}

.flex-container-header {
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  justify-content: space-between;
}

.noti-box {
  margin-bottom: 10px;
}

.itens-menu {
  display: flex;
  flex-direction: row;
  white-space: normal;
  justify-content: space-evenly;
  align-items: center;
}

.scroll {
  max-height: 400px;
  overflow-y: scroll;
}

/* X */
.exit-button {
  margin-right: 10px;
  cursor: auto;
  cursor: pointer;
}


/* Botão Perfil */
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Nome/Função Perfil */
.flex-container-names {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.fontBlue {
  color: #5C85FF;
  font-weight: 400;
}

.bolinha-vermelha {
  position: relative;
  left: -3px;
  top: -5px;
  width: 10px;
}

.bolinha-vermelha-svg {
  margin-right: 0;
  padding: 0;
}

.cursorDefault {
  cursor: default !important;
}

.nameMenu {
  font-weight: bold;
  text-align: left;
}

.namePosition {
  text-align: left;
}

.mt18 {
  margin-top: 18px;
}

.mt10 {
  margin-top: 10px;
}

.mt6 {
  margin-top: 6px;
}

.mt3 {
  margin-top: 3px;
}

.mt2 {
  margin-top: 2px;
}


.mb56 {
  margin-bottom: 56px
}

.mb7 {
  margin-bottom: 7px;
}


.w237 {
  width: 237px;
}

.w200 {
  width: 200px;
}

.w160 {
  width: 160px;
}

.w120 {
  width: 120px;
}

.w94 {
  width: 94px;
}

.w75 {
    width: 83px;
}

.w60 {
  width: 60px;
}

.w50 {
  width: 50px;
}

.w40 {
  width: 40px;
}

.w30 {
  width: 30px;
}

.mr35 {
  margin-right: 35px;
}

.mr38 {
  margin-right: 38px;
}

.mr44 {
  margin-right: 44px;
}

.w229 {
  width: 229px;
}

.w100 {
  width: 100px;
}

.minW46_18vw {
  min-width: calc(46px + 18vw);
}

.minW99_22vw {
  min-width: calc(30vw);
}

@media (max-width: 1399px) {
  .minW70_30vw {
    min-width: calc(120px + 14vw);
  }
}
@media (min-width: 1400px) {
  .minW70_30vw {
    min-width: calc(36vw);
  }
}


.minW124 {
  min-width: 124px;
}

.minW94 {
  min-width: 94px;
}

.minW66_6vw {
  min-width: calc(66px + 6vw);
}


.minW46_16vw {
  min-width: calc(50px + 16vw);
}

.minW70 {
  min-width: 70px;
}

.minW60_8vw {
  min-width: calc(60px + 8vw);
}

.minW50_6vw {
  min-width: calc(50px + 6vw);
}

.minW70_3vw {
  min-width: calc(70px + 3vw);
}

.minW50_12vw {
  min-width: calc(50px + 12vw);
}

.tagBtn {
  border: 1px solid #D8DAE5 !important;
  background: #F3F6FF !important;
  border-radius: 4px 0px 0px 4px !important;
}

.tagBtn span {
  color: #3366FF;
  font-weight: 500;
}

.fw700 span {
  font-weight: 700;
}

.mt27 {
  margin-top: 27px;
}

.alignStart {
  align-items: start;
}

.h65 {
  height: 65px;
}

.hAuto {
  height: auto !important;
}
.w230 {
  width: 230px;
}

.h500 {
  height: 500px;
}

.pt15 {
  padding-top: 15px;
}

.pt10 {
  padding-top: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb60 {
  padding-bottom: 60px;
}

.wsNormal {
  white-space: normal;
}

.fw400 {
  font-weight: 400 !important;
}

.btnflex474 span {  
  text-overflow: ellipsis;
  overflow: hidden;
}

.btnflex474 {
  max-width: calc(22px + 16vw);
}

.wsNormal span {
  white-space: normal;
}

.border-box{
  border: 1px solid #D8DAE5;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.tabList {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 10px;
  width: 110px !important;
  justify-content: flex-start !important;
}

.il-flex {
  display: inline-flex;
}

.mr10 {
  margin-right: 10px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.font12 {
  font-size: 12px !important;
}

.w250 select,
.w250 {
  width: 250px !important;
}

.colorGray {
  color: #696F8C;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt46 {
  margin-top: 46px;
}

.bgWhite {
  background: white;
}

.alignRight {
  display: flex;
  justify-content: space-between;
}

.mb20 {
  margin-bottom: 20px;
}

.w200 {
  width: 200px !important;
}

.bgF3F6FF {
  background: #F3F6FF;
}

.alignCenter {
  flex-direction: column !important;
  align-items: center;
  padding-top: 20px;
}

.itemsCenter {
  align-items: center !important;
}

.btnAlign {
  display: flex;
  align-items: center;
}

.btnAlign :first-child {
  color: white;
  margin-right: 10px;
}

.btnAlign :last-child {
  color: white;
}

.w100_ {
  width: 77%;
}

.gap13 {
  gap: 13px;
}

.gap8 {
  gap: 8px;
}

.pl0 {
  padding-left: 0 !important;
}

.pr17 {
  padding-right: 17;
}

.fw600 {
  font-weight: 600 !important;
}

.hiddenOverflow {  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.flexColl {
  display: flex !important;
  flex-direction: column;
}

.h40 {
  height: 40px
}

.flexEnd {
  justify-content: flex-end;
}

.absolute {
  position: absolute !important;
}

.flexStart {
  justify-content: flex-start;
}

.divChildMr20 div {
  margin-right: 20px;
}

.w135 {
  width: 135px !important;
}

.mb15 {
  margin-bottom: 15px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb29 {
  padding-bottom: 29px;
}

.pt5 {
  padding-top: 5px;
}

.pt29 {
  padding-top: 29px;
}

.mt16 {
  margin-top: 16px;
}

.mt22 {
  margin-top: 22px;
}

.color696F8C {
  color: #696F8C;
}

.color101840 {
  color: #101840;
}

.f10,
.table .f10 span {
  font-size: 10px;
}

.w400 {
  width: 400px;
}

.mr20 {
  margin-right: 20px;
}

.mt19 {
  margin-top: 19px;
}

.mr60 {
  margin-right: 60px;
}

.sobrepor {
  margin-left: -20px;
  margin-top: 32px;
}

.pr25 {
  padding-right: 25px !important;
}

.border-top-Blue {
  border-top: 1px solid #3366FF;
}

.border-bottom-Blue {
  border-bottom: 1px solid #3366FF;
}

.mt40 {
  margin-top: 40px;
}

.mt15 {
  margin-top: 15px;
}

.mt26 {
  margin-top: 26px;
}

.pl10 {
  padding-left: 10px !important;
}

.pl28 {
  padding-left: 28px !important;
}

.pl26 {
  padding-left: 26px !important;
}

.mb22 {
  margin-bottom: 22px;
}

.mb40 {
  margin-bottom: 40px;
}

.lh19 {
  line-height: 19px;
}

.lh24 {
  line-height: 24px;
}

.lh32 {
  line-height: 32px;
}

.mb47 {
  margin-botton: 47px;
}

.divMRight4 button {
  margin-right: 4px;
}

.pd0 {
  padding: 0px;
}

.mt7{
  margin-top: 7px;
}

.mt47 {
  margin-top: 47px;
}

.mb19 {
  margin-bottom: 19px;
}

.f14 {
 font-size: 14px;
}

.f16 {
 font-size: 16px;
}

.f18 {
  font-size: 18px;
 } 

.mb12 {
  margin-bottom: 12px;
}

.displayBlock {
  display: block;
}

.colorBlue {
  color: #3366FF;
}

.mt35 {
  margin-top: 35px;
}

.mb5 {
  margin-bottom: 5px;
}

.w100 {
  width: 100px !important;
}

.w120 {
  width: 120px !important;
}

.w80 {
  width: 80px !important;
}

.textEllipsis div {  
  overflow: hidden;
  text-overflow: ellipsis;
}

.overHidden {
  overflow: hidden;
}

.h170 {
  height: 170px;
}

.fw500 {
  font-weight: 500 !important;
}

.pl10 {
  padding-left: 10px;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb8 {
  margin-bottom: 8px;
}

.mt30 {
  margin-top: 30px;
}

.mt36 {
  margin-top: 36px;
}

.childSpanMl0 span {
  margin-left: 0px !important;
}

.childSpanMr0 span {
  margin-right: 0px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.w195 {
  width: 195px;
}

.mb-15 {
  margin-bottom: -15px;
}

.mr17 {
  margin-right: 17px;
}

.mr18 {
  margin-right: 18px;
}

.mr42 {
  margin-right: 42px;
}

.w97 {
  width: 97px;
}

.w68 {
  width: 68px;
}

.p10 {
  padding: 10px;
}

.w205 {
  width: 205px;
}

.mt20 {
  margin-top: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.pb0 {
  padding-bottom: 0;
}

.mb29 {
  margin-bottom: 29px;
}

.mr16 {
  margin-right: 16px;
}

.w134 {
  width: 134px;
}

.mt29 {
  margin-top: 29px;
}

.w392 {
  width: 392px;
}

.mt8 {
  margin-top: 8px;
}

.row-msg-apoio{
  display: flex;
  align-items: center;
  margin: 25px 0;
}

.row-msg-apoio .msg-plan-apoio{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #317159;
}


/*Planilha Comissão*/
.atualizacao-comissao{
  font-weight: 400;
  text-align: right;
  color: #474D66;
  font-size: 14px;
}
.atualizacao-comissao span{
  font-weight: 600;
  font-family: Montserrat;
  color: #474D66;
}

.planilha-comissao h1{
  font-weight: 600;
  font-size: 20px;
}

.planilha-comissao .subtitle{
  font-weight: 400;
  font-size: 16px;
}

.planilha-comissao .content ul li{
  list-style: none;
  margin-bottom: 5px;
  font-weight: 400;
}

.planilha-comissao .content .loja li span,
.planilha-comissao .content .conta li span,
.planilha-comissao .content .valores li span{
  font-weight: 700;
}

.planilha-comissao .content .conta{
  margin: 25px 0;
}

.planilha-comissao .content .conta ul{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.planilha-comissao .content .conta ul li{
  margin-right: 10px;
}

.planilha-comissao .content .table .center{
  text-align: center;
}

.planilha-comissao .content .table .right{
  text-align: right;
  padding-right: 15px;
}

.planilha-comissao .content .valores{
  margin-bottom: 25px;
}

.planilha-comissao .footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
}

.planilha-comissao .footer button{
  margin-left: 15px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml28 {
  margin-left: 28px;
}

.ml109 {
  margin-left: 109px;
}

.ml280 {
  margin-left: 280px;
}


.f12 {
  font-size: 12px;
}

.mr5 {
  margin-right: 5px;
}

.textCenter {
  text-align: center;
}

.p5 {
  padding: 5px;
}

.f24 {
  font-size: 24px !important;  
}

.DecorationNone {
  text-decoration: none;
}

.colorNeutral {  
  color: #474D66;
}

.mt33 {
  margin-top: 33px;
}

.mt34 {
  margin-top: 34px;
}


.mt59 {
  margin-top: 59px;
}

.ml50 {
  margin-left: 50px;
}

.ml-28 {
  margin-left: -28px;
}

.mt24 {
  margin-top: 24px;
}

.mb14 {
  margin-bottom: 14px;
}

.lw32 {  
  line-height: 32px;
}

.lw20 {
  line-height: 20px;
}

.lw24 {
  line-height: 24px;
}

.mw200 {
  max-width: 200px;
}

.mw140{
  width: 100%;
  min-width: 140px;
}

.bg-table-blue{
  background: #F3F6FF;
}

.label-filter-order{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #474D66;
  margin-left: 8px;
}

.filter-position{  
  margin-left: 0;
  margin-right: 0;
}

.label-align-filter-order{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.filter-table-nav{
  padding: 0;
}

.table-cell span{
  font-size: 14px;
}

/*Adicionar 3 pontinhos no nome do Gerente quando esceder o tamanho da celular*/
.table-cell .dot-text{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; 
  width: 100%;
  display: block;
  font-weight: inherit;
}

/*Painel Financeiro*/
.chart{
  width: 100%;
  height: auto;
  background: #fff;
}

.chart .header{
  background: #f9fafc;
  width: 100%;
}

.chart .header ul{
  list-style: none;display: flex;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid #E6E8F0;
}

.chart .header ul li{
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #696F8C;
  display: flex;
  align-items: center;
}

.chart .content{
  background: #fff;
}

.chart .w1360{
  width: 100%;
  /*max-width: 1360px;*/
  height: auto !important;
  max-height: 550px; /*365px*/
  cursor: pointer;
  padding: 20px
}

@media (max-width: 1360px){
  .chart .w1360{
    max-height: 365px;
    /*height: 350px;*/
  }
}

.chart .header .select-chart{
  /*padding: 20px 20px 0 20px;*/
}

.chart .header .select-chart ul{
  list-style: none;
  display: flex;
  width: 100%;
  padding: 0;
  border-bottom: inherit;
  align-items: center;
}

.chart .select-chart ul li{
  margin-right: 15px;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #696F8C;
}

.chart .select-chart ul li:first-child{
  margin-right: 30px;
}

.chart .select-chart ul li.active{
  font-weight: 700;
}

.chart .select-chart ul li.label-year{
  margin-right: 15px; 
  cursor: default;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  color: #474D66;
  text-transform: initial;
}

.info-grafico{
  color: #000000;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  display: flex;
  list-style: none;
  margin-top: 20px
}

.info-grafico li{
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 14px;
}

.info-grafico .indicador{
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.info-grafico .cor-menor{
  background: #A3E6CD;
}

.info-grafico .cor-meio{
  background: #52BD94;
}

.info-grafico .cor-maior{
  background: #429777;
}

.row-periodo-financeiro{
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  background: #8F95B2;
  color: #fff;
  padding: 20px;
  margin-top: 0;
}

.table-vendas-financeiro .valor{
  color: #429777;
  font-weight: 400;
}

.table-vendas-financeiro .revisao-valor{
  color: #FFB020;
  font-weight: 400;
}

.padd-ltd0-r20{
  padding: 0 0 0 20px !important;
}

.pagination-end{
  justify-content: end;
}

/*Rank dos Gerentes*/
.rank .container{
  padding: 20px 0;
  height: auto;
}

.rank .container .rocket{
  padding: 20px;
  border: 1px solid #D8DAE5;
  border-radius: 4px;
}

.rank .container .rocket h2{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #52BD94;
  margin-top: 15px;
  margin-bottom: 15px;
}

.rank .container .rocket p{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;  
  color: #474D66;
  margin-bottom: 15px;
}

.rank .container .content p{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #474D66;
  margin-bottom: 15px;
}

.rank .container .content p:first-child{
  margin: 25px 0 30px 0;
}

.rank .container .title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #474D66;
  margin-bottom: 25px;
}

.rank .container .title-span{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #474D66;
  margin-bottom: 10px;
  display: block;
}

.rank .container .positionIcon{
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.rank .container .melhorias p{
  display: flex;
  margin-bottom: 15px;
}

.rank .container .blue-line,
.rank .form-detalhes .blue-line{
  background: #3366FF;
  height: 1px;
  margin-bottom: 35px
}

.rank .container ul{
  display: flex;
}

.rank .container ul li{
  list-style: none;
  margin-right: 50px;
  margin-bottom: 25px;
}

.rank .form-detalhes .subtitle{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #474D66;
}

.rank .container ul li .field,
.rank .container .row .field,
.rank .form-detalhes .fontStatus,
.rank .form-detalhes .fontStatus span{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px; 
  color: #101840;
  margin-bottom: 10px;
}

.rank .container ul li .value,
.rank .container .row .value,
.rank .form-detalhes .mensagem{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #696F8C;
}

.detalhes-rank .css-9r9n92:not([aria-current="true"]):not([aria-checked="true"]):not(:focus):not(:active):hover {
  background-color: #fff !important;
}

.detalhes-rank .css-9r9n92{
  border-bottom: 0;
}

.detalhes-rank .ub-pl_12px:first-child{
  padding-left: 0;
}

.detalhes-rank .ttp{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.tab-rank{
  border-bottom: 1px solid #E6E8F0;
}

.rank .form-detalhes .css-9r9n92:not([aria-current="true"]):not([aria-checked="true"]):not(:focus):not(:active):hover{
  background: #fff !important;
}

.rank .form-detalhes .css-9r9n92{
  border-bottom: 0;
  margin-bottom: 20px;
}

.rank .head-rank-user{
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.rank .type-rank-user{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.rank .type-rank-user .btn-editar{
  border: 1px solid #D8DAE5;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #696F8C;
}

.footer-dialog{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

/*Dialog Confirmar Escritura*/
.dialog-escritura{
  height: 100%;
  border-radius: 10px !important;
  background-color: transparent;
}

.dialog-escritura .ub-dspl_flex{
  padding: 0;
  border-radius: 8px;
}

.dialog-escritura > div{
  margin: 80px 0;
}

.dialog-escritura .header{
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #E6E8F0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 20px 0;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dialog-escritura .header .p20{
  display: flex;
  padding: 0 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.dialog-escritura .header h1{
  margin-bottom: 0;
}

.dialog-escritura .content{
  padding: 0 40px;
}

.dialog-escritura img{
  display: block;
  margin: 40px auto 40px;
  padding-top: 0;
}

.dialog-escritura .row{
  margin-top: 45px;
  margin-bottom: 0;
}

.dialog-escritura .row .coll {
  border: 1px solid #D8DAE5;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 15px;
}

.dialog-escritura .row .coll label{
  color: #474D66;
  font-size: 14px;
  font-weight: 400;
}

.dialog-escritura .row .coll .radio div svg circle{
  color: #fff !important;
}

.dialog-escritura .row .coll .radio span{
  color: #474D66;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  display: block;
}

.dialog-escritura .row .coll .sub-radio span{
  font-weight: 400;
  font-size: 14px;
}

.dialog-escritura .row .coll .sub-questions{
  display: flex;
  margin-left: 22px;
  margin-top: 15px;
}

.dialog-escritura .row .coll .sub-questions .fields{
  margin-right: 20px
}

.dialog-escritura .row .coll .dialogSubTitle{
  padding-left: 24px;
  color: #474D66;
}

.dialog-escritura .row .coll .textarea{
  margin-top: 16px;
  display: block;
}

.dialog-escritura .dialog-footer{
  border-top: 1px solid #E6E8F0;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  left: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dialog-escritura .dialog-footer .p20-end{
  display: flex;
  padding: 0 40px;
  width: 100%;
  justify-content: end;
}

.dialog-escritura .dialog-footer .p20{
  display: flex;
  padding: 0 40px;
  width: 100%;
  justify-content: inherit;
}

.react-sweet-progress-line-inner{
  min-height: 5px !important;
}

.react-sweet-progress-symbol{
  color: #696F8C !important;
  font-weight: 400 !important;
}

.dialog-escritura .dialog-footer .btn-minimal{
  position: relative;
  margin: 0;
  right: 0;
}

.dialog-escritura .uploadError {
  font-size: 12px;
  font-weight: 700;
  padding-left: 0;
}

.row-mobile{
  display: none;
}

.flex .row-btn{
  gap: 5px;
  display: flex;
}

.line-box-doc{
  display: none;
}

@media (max-width: 800px) {
  .dialog-escritura .row .coll .sub-questions{
    display: block;
    margin: 25px;
  }
}

@media (max-width: 600px) {
  .dialog-escritura .header h1 {
    font-size: 18px;
  }

  .dialog-escritura .content{
    padding: 0 20px;
  }

  .dialog-escritura .header .p20,
  .dialog-escritura .dialog-footer .p20-end,
  .dialog-escritura .dialog-footer .p20{
    padding: 0 20px;
  }
}

@media (max-width: 400px) {
  .dialog-escritura .header h1 {
    font-size: 16px;
  }
}

/*Informações sobre a Escritura*/
.info-escritura .row{
  margin-bottom: 15px;
}

.info-escritura .row .col{
  width: 100%;
  display: flex;
}

.info-escritura .row .col div:first-child{
  margin-right: 40px;
  max-width: 350px
}

.info-escritura .row .label{
  margin-bottom: 5px;
  display: block;
}

.info-escritura .row span{
  font-family: Montserrat;
  /*font-size: 14px;*/
  font-weight: 600;
}

.info-escritura .row p{
  font-weight: 400;
  /*font-size: 14px;*/
  line-height: 23px;
}
