.redefinirSenha::before {
  background-color: #f0f0f5 !important;
}

.title img {
  margin-bottom: 20px;
}

.title h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.rodape {
  margin-bottom: 25px;
}

.ub-pb_24px {
  padding-bottom: 0px !important;
}

.dialog b {
  font-weight: 700;
}

.dialog p.EnviarEmail {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
